<template>
  <div class="container">
    <div>
      <form
        v-if="!isMutiFactor"
        class="modal-content animate"
        @submit.prevent="signupUser"
      >
        <div class="imgcontainer">
          <img
            width="300"
            src="https://www.maropost.com/wp-content/themes/Maropost/img/logos/Maropost_Logo.svg"
            alt="Avatar"
          />
          <br />
          <h1 class="red">Sign up</h1>
        </div>

        <div class="container">
          <label for="uname"><b>Email</b></label>
          <input
            v-model="credentials.email"
            type="text"
            placeholder="Enter Email"
            name="uname"
            required
          />

          <label for="psw"><b>Password</b></label>
          <input
            type="password"
            v-model="credentials.password"
            placeholder="Enter Password"
            name="psw"
            required
          />

          <button type="submit">
            {{ isLoading ? "Loading...." : "Sign up" }}
          </button>
          <div class="mt-4">
            <alert-message
              v-if="!!errorMessage"
              :text="errorMessage"
              type="danger"
            />
          </div>
        </div>
      </form>
    </div>

    <muti-factor-form
      v-if="isMutiFactor"
      @submit="enrollMultiFactor"
      :isSmsVerified="!!smsVerificationId"
      :isLoading="isLoading"
      :credentials="credentials"
      showPhoneNumber
    />

    <div id="recaptcha-container" />
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import firebase from "firebase/app";
import MutiFactorForm from "@/components/MutiFactorForm.vue";

import AlertMessage from "@/components/shared/AlertMessage.vue";

const auth = firebase.auth();

/**
 * User sign up page
 */
export default {
  name: "SignUp",
  /**
  |--------------------------------------------------
  | Components
  |--------------------------------------------------
  */
  components: { MutiFactorForm, AlertMessage },
  /**
  |--------------------------------------------------
  | Data properties
  |--------------------------------------------------
  */
  data() {
    return {
      errorMessage: "",
      isLoading: false,
      credentials: { phoneNumber: "", verificationCode: "" },
      smsVerificationId: "",
      recaptchaVerified: false,
      recaptchaVerifier: null,
      recaptchaVerifiedToken: "",
      multiFactorSession: null,
      isLoggedIn: false,
      isMutiFactor: false,
      authResolver: null,
      newUserDetails: {},
    };
  },
  /**
  |--------------------------------------------------
  | Methods
  |--------------------------------------------------
  */
  methods: {
    /**
     * Maps mutations in component
     */
    ...mapMutations({
      showLoader: "auth/SHOW_LOADER",
    }),
    /**
     * Maps action in component
     */
    ...mapActions({
      setCurrentUserDetails: "auth/setCurrentUserDetails",
    }),
    /**
     * Gets user multi factor assertion
     */
    async getUserMultiFactorSession() {
      this.multiFactorSession = await auth.currentUser.multiFactor.getSession();
    },
    /**
     * Sends verification code to user phone number in the form
     */
    async sendVerificationMessage() {
      try {
        this.setIsLoading(true);
        await this.getUserMultiFactorSession();

        var phoneAuthProvider = new firebase.auth.PhoneAuthProvider(auth);
        // Specify the phone number and pass the MFA session.
        const phoneInfoOptions = {
          phoneNumber: this.credentials.phoneNumber,
          session: this.multiFactorSession,
        };
        // Send SMS verification code.
        this.smsVerificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          this.recaptchaVerifier
        );
      } catch (error) {
        console.log("error :>> ", error);
      } finally {
        this.setIsLoading(false);
      }
    },
    /**
     * Enrolls a new user phone number as Multi factor authentication
     * @listens submit Handles form submition and user phone verification and mutlti factor activation
     */
    async enrollMultiFactor() {
      try {
        this.setIsLoading(true);

        const { verificationCode } = this.credentials;

        if (!this.smsVerificationId && this.credentials.phoneNumber) {
          this.sendVerificationMessage();
        } else if (verificationCode) {
          this.verifySmsCode();
        }
      } catch (error) {
        console.log("error :>> ", error);
      } finally {
        this.setIsLoading(false);
      }
    },
    setIsLoading(val) {
      this.isLoading = val;
      this.showLoader(val);
    },
    /**
     * Verifies sms code sent to user phone number
     */
    async verifySmsCode() {
      // Ask user for the verification code.
      try {
        this.setIsLoading(true);
        var cred = firebase.auth.PhoneAuthProvider.credential(
          this.smsVerificationId,
          this.credentials.verificationCode
        );
        var multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(
          cred
        );

        // Complete enrollment. This will update the underlying tokens
        // and trigger ID token change listener.
        await auth.currentUser.multiFactor.enroll(
          multiFactorAssertion,
          this.credentials.phoneNumber
        );

        // Dispatches action in store to authenticate the user
        this.setCurrentUserDetails(this.newUserDetails);
      } catch (error) {
        console.log("error :>> ", error);
      } finally {
        this.setIsLoading(false);
      }
    },
    async signupUser() {
      try {
        this.setIsLoading(true);
        const { email, password } = this.credentials;

        this.newUserDetails = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password);
        if (this.newUserDetails) {
          await firebase.auth().currentUser.sendEmailVerification({
            url: "http://localhost:8080/#/signup",
          });
        }
        this.enrollMultiFactor();
        this.isMutiFactor = true;
      } catch (error) {
        if (error.code === "auth/email-already-in-use") {
          this.errorMessage = error.message;
        }
        this.showError(error);
        // console.log(`error`, error.code);
      } finally {
        this.setIsLoading(false);
      }
    },
    showError(error) {
      this.errorMessage = error?.message;
    },
    verifyRecaptchaVerifier() {
      this.setIsLoading(true);
      this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: function(response) {
            // reCAPTCHA solved, you can proceed with phoneAuthProvider.verifyPhoneNumber(...).
            this.recaptchaVerified = !!response;
          },
        }
      );

      this.recaptchaVerifier.render();
      this.setIsLoading(false);
    },
  },
  /**
  |--------------------------------------------------
  | Mounted lifecycle hook
  |--------------------------------------------------
  */
  mounted() {
    this.verifyRecaptchaVerifier();
  },
};
</script>
